<template>
  <div>
    <vx-page-title-actions>
      <vx-button
        :can="canCreate"
        variant="primary"
        @click="openModal()"
      >
        Add
      </vx-button>
    </vx-page-title-actions>
    <vx-table
      ref="tableRef"
      :columns="columns"
      :resource="tableResource"
      :hide-per-page="true"
    />
    <b-modal
      v-model="modalIsOpen"
      :title="modalTitle"
      hide-footer
      title-tag="h4"
      @close="closeModal"
    >
      <tag-editor :tag="entity" @submit="handleSubmit" />
    </b-modal>
  </div>
</template>

<script>
import VxTable from '@/components/table/vx-table/VxTable'
import { tags, useResource, tagsVisibility, passDataToResource } from '@/services/resources'
import { VxCellBadges, VxCellButtons } from '@/components/table'
import { buttons } from '@/components/table/vx-table-cells/vx-cell-button'
import { VxButton } from '@/components/buttons'
import VxPageTitleActions from '@/components/layout/VxPageTitleActions'
import { computed, ref } from '@vue/composition-api'
import { BModal } from 'bootstrap-vue'
import TagEditor from '@/views/tags/TagEditor'
import { useModalEditor } from '@/services/modal'

export default {
  name: 'Tags',
  components: {
    VxTable,
    VxPageTitleActions,
    VxButton,
    VxCellBadges,
    VxCellButtons,
    TagEditor,
    BModal
  },
  setup () {
    const tableRef = ref(null)

    const { modalIsOpen, entity, openModal, closeModal } = useModalEditor()

    const { can: canCreate } = useResource(tags.create)
    const { can: canUpdate } = useResource(tags.update)

    const modalTitle = computed(() => {
      return (entity.value ? 'Edit' : 'Create') + ' Tag'
    })

    const handleSubmit = () => {
      closeModal()
      tableRef.value.refresh()
    }

    const columns = [
      { key: 'id' },
      { key: 'name' },
      { key: 'description' },
      {
        key: 'visibility',
        component: VxCellBadges,
        tdAttr: {
          colors: {
            [tagsVisibility.private]: 'danger',
            [tagsVisibility.public]: 'success',
            [tagsVisibility.hidden]: 'secondary'
          }
        }
      },
      {
        key: 'actions',
        component: VxCellButtons,
        tdAttr: (value, key, { id }) => ({
          buttons: [
            {
              ...buttons.edit,
              can: canUpdate,
              onClick: openModal
            },
            {
              ...buttons.delete,
              resource: passDataToResource(tags.delete, {
                requestParams: { urlParams: { id } }
              })
            }
          ]
        })
      }
    ]

    return {
      tableRef,
      tableResource: tags.getAll,
      columns,
      canCreate,

      entity,
      handleSubmit,

      modalTitle,
      modalIsOpen,
      openModal,
      closeModal
    }
  }
}
</script>
