<template>
  <vx-form
    v-slot="{ loading }"
    :resource="formResource"
    @submit="handleSubmit"
  >
    <vx-input
      v-model="formValue.name"
      :rules="rules.name"
      name="name"
      label="Name"
    />

    <vx-input
      v-model="formValue.description"
      :rules="rules.description"
      name="description"
      label="Description"
    />

    <vx-select-resource
      v-model="formValue.visibility"
      :resource="visibilitiesResource"
      :rules="rules.visibility"
      label="Visibility"
      name="visibility"
    />

    <vx-button
      :loading="loading"
      variant="primary"
      type="submit"
    >
      Save
    </vx-button>
  </vx-form>
</template>

<script>
import { reactive } from '@vue/composition-api'
import { setValuesToForm } from '@/services/form'
import { VxForm, VxInput, VxSelectResource } from '@/components/form'
import { VxButton } from '@/components/buttons'
import { passDataToResource, tags } from '@/services/resources'

export default {
  name: 'TagEditor',
  components: {
    VxSelectResource,
    VxForm,
    VxInput,
    VxButton
  },
  props: {
    tag: {
      type: Object,
      default: null
    }
  },
  setup ({ tag }, { emit }) {
    const isEdit = !!tag

    const formResource = isEdit
      ? passDataToResource(tags.update, {
        requestParams: { urlParams: { id: tag.id } }
      })
      : tags.create

    const visibilitiesResource = tags.visibilities

    const formValue = reactive({
      name: '',
      description: '',
      visibility: null
    })

    const rules = {
      name: {
        required: true,
        min: 2,
        max: 1000
      },
      description: {
        required: true,
        min: 2,
        max: 2000
      },
      visibility: {
        required: true
      }
    }

    if (isEdit) setValuesToForm(tag, formValue)

    const handleSubmit = ([, res]) => {
      if (res) {
        emit('submit', res)
      }
    }

    return {
      formValue,
      formResource,
      rules,
      handleSubmit,
      visibilitiesResource
    }
  }
}
</script>
